import React from "react";
import { Field, Form } from "react-final-form";
import { composeValidators, requiredValidator } from "components/form/validators";
import { TextAreaField, TextField } from "components/form/fields";
import { Button } from "primereact/button";

interface IProps{
  initialData?: object | undefined;
  onSubmit: (values: any) => void;
  onCancel: (e: any) => void;
}

export const FormKitchenWin: React.FC<IProps> = (props: IProps) => {
  const { initialData = { name: '', description: '' }, onSubmit, onCancel } = props;

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialData}
      render={({ handleSubmit }) => (
            
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <Field
              validate={composeValidators(requiredValidator)}
              name="name" label="Наименование:" render={TextField}
              settings={{ placeholder: "Кухня...", }}
            />
          </div>

          <div className="col-12">
            <Field
              validate={composeValidators(requiredValidator)}
              name="description" label="Описание:" render={TextAreaField}
            />
          </div>
              
          <div className="col-12 p-0 flex flex-wrap mt-1 mb-2 justify-content-between">
            <div className="col-12 md:col-6">
              <Button 
                label="Отмена" 
                className="col-12 p-button-raised p-button-plain p-button-text" 
                onClick={onCancel}/>
            </div>
            <div className="col-12 md:col-6">
              <Button label="Применить" className="col-12 p-button-raised p-button-success "/>
            </div>
          </div>
        </form>
      )}
    />
  );
}

export default FormKitchenWin;