import React, { useEffect, useRef, useState } from 'react';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import { IGallery } from 'components/mergeGallery';
import DeleteImageWin from '../delete.images.win';
import { useDispatch } from 'react-redux';
import { show } from 'features/windows/slice';
import { IRestaurant } from 'app/interfaces/restaurants';
import { useUpdateRestaurantGalleryMutation } from 'app/services/restaurants';

interface IProps {
  restaurant: IRestaurant;
}

export const GalleriaImage: React.FC<IProps> = (props:any, ref:any) => {
  const { restaurant } = props;
  const [ _gallery, setGallery ] = useState<IGallery[]>([]);
  const dispatch = useDispatch();
  const galleria = useRef<any>(null);
  const [ reload, setReload ] = useState(false);
  const [ image, setImage ] = useState<IGallery | null>(null);
  const [ activeIndex, setActiveIndex ] = useState(0);
  const [updateGallery] = useUpdateRestaurantGalleryMutation();
  const [ responsiveOptions ] = useState([
    {breakpoint: '1024px', numVisible: 5 },
    {breakpoint: '960px',  numVisible: 4 },
    {breakpoint: '768px',  numVisible: 3 },
    {breakpoint: '560px',  numVisible: 1 }
  ]);

  useEffect( () => setGallery(restaurant.gallery), [restaurant] )

  const handleDelete = (e: any, image: IGallery) => {
    e.preventDefault();
    setImage(image)
    dispatch(show({type: 'restaurants', name: 'deleteImage'}))
  }
  const deleteImageGallery = (image: IGallery) => {
    const index = _gallery.indexOf(image)
    if(index !== -1) {
      updateGallery({restaurantId: restaurant.id, gallery: _gallery.filter( i => i.link !== image.link )})
        .then( () => {
          setGallery( (prev) => {
            return prev.filter( i => i.link !== image.link);
          } )
        })
    }
    setReload( true )
    setTimeout(() => setReload( false ), 1000)
  }

  const thumbnailTemplate = (item: any) => (
    <div className="grid grid-nogutter justify-content-center">
      <img src={`/api/files/${item.link}`} width="50" alt={item?.alt} style={{ display: 'block' }} />
    </div>
  )

  const renderImages = _gallery.map((image: any, index: number) => {

    let imgEl = <img className="galleria-img" src={`/api/files/${image.link}`} alt={image.alt} style={{ cursor: 'pointer'}} onClick={() => {
      setActiveIndex(index);
      if(galleria && galleria.current) galleria.current.show()
    }} />
    return (
      <div className="col-12 md:col-6 lg:col-4 xl:col-3 gallery-images-list" key={index}>
        {imgEl}
        <Button icon="pi pi-trash" className="p-button-rounded p-button-danger p-button-outlined" onClick={(e)=>handleDelete(e, image)}/>
      </div>
    )
  })

  const itemTemplate = (item: any) => <img src={`/api/files/${item.link}`} alt={item?.alt} style={{ width: '100%', display: 'block' }} />

  return (
    <div className="galleria">
      <div className="card" key={Number(reload)}>

          <Galleria ref={galleria} value={_gallery} responsiveOptions={responsiveOptions} numVisible={7} style={{ maxWidth: '850px' }}
            activeIndex={activeIndex} onItemChange={(e) => setActiveIndex(e.index)}
            circular fullScreen showItemNavigators showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate}/>

          <h4>Всего: {_gallery.length}</h4>
          <ScrollPanel style={{ width: '100%', height: '450px' }} className="gallery-scroll" >
            <div className="grid p-d-flex p-flex-wrap">
              { renderImages }
            </div>
          </ScrollPanel>
      </div>

      <DeleteImageWin image={image} deleteImageGallery={deleteImageGallery}/>
    </div>
  );
}

export default GalleriaImage;
