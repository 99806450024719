import React from "react";
import { Outlet } from "react-router-dom";
// import { getRestaurantCategories } from "domain/actions/restaurants.action";

export const MainRestaurantsPage: React.FC = (props: any) => {
  // const { getRestaurantCategories } = props;

  // useEffect(() => getRestaurantCategories(), []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Outlet />
    </>
  );
}

export default MainRestaurantsPage;