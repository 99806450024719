import React from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { hide } from "features/windows/slice";
import { useWindows } from "hooks/useWindows";
import { ICategory } from "app/interfaces/categories";
import FormKitchenWin from "./form.kitchen.win";
import { useCreateKitchenMutation } from "app/services/kitchen";

export const AddKitchenWin: React.FC = () => {
  const dispatch = useDispatch();
  const windows = useWindows();
  const [ createKitchen ] = useCreateKitchenMutation();

  const handleClose = () => {
    dispatch(hide({type: 'kitchen', name: 'add'}))
  };
  const onSubmit = (values: ICategory) => {
    createKitchen( values ).unwrap()
      .then( () => handleClose() )
  };

  return (
    <Dialog header="Создание категории" visible={windows.kitchen.add} className="col-12 p-0 md:col-6 lg:col-4" onHide={handleClose}>
      <FormKitchenWin onSubmit={onSubmit} onCancel={(e: any) => {
        e.preventDefault();
        handleClose()
      } }/>
    </Dialog>
  );
}

export default AddKitchenWin;