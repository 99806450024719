import React, { useEffect, useMemo, useState } from "react";

import { Button } from "primereact/button";
import { IDishes, IRestaurant } from "app/interfaces/restaurants";
import { ScrollPanel } from "primereact/scrollpanel";
import { useDispatch } from "react-redux";
import { show } from "features/windows/slice";
import { groupBy } from "lodash";
import { useGetCategoriesKitchenQuery } from "app/services/requisites";
import CategoryMenu from "./category.menu";
import AddDishesWin from "../add.dishes.win";
import EditDishesWin from "../edit.dishes.win";
import { useGetRefreshRestaurantMutation } from "app/services/restaurants";
import DeleteKitchenWin from "../delete.kitchen.win";
import { ICategory } from "app/interfaces/categories";
import { useDeleteDishesByKitchenMutation } from "app/services/kitchen";
import { useMediaQuery } from "react-responsive";

interface IProps {
  restaurant: IRestaurant;
  setPage: (page:string) => void;
}

export const KitchenEdit: React.FC<IProps> = (props: IProps) => {
  const { restaurant, setPage } = props;
  const [ menu, setMenu ] = useState<any>({});
  const [ item, setItem ] = useState<IDishes | null>(null);
  const [ category, setCategory ] = useState<ICategory | null>(null);
  const dispatch = useDispatch()
  const { categories } = useGetCategoriesKitchenQuery(null, {
    selectFromResult: ({ data }) => ({ categories: data }),
  });
  const [ getRestaurantById, { data } ] = useGetRefreshRestaurantMutation();
  const [ deleteCategory ] = useDeleteDishesByKitchenMutation();
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const refresh = () => getRestaurantById({id:restaurant.id})
  const handleAdd = () => dispatch( show({type: 'dishes', name: 'add'}) );
  const handleDeleteDishes = (category: ICategory) => {
    setCategory(category)
    dispatch( show({type: 'kitchen', name: 'delete'}) );
  };
  const deleteCategoryKitchen = (categoryId: string) => {
    deleteCategory( {id: categoryId, restaurantId: restaurant.id} ).unwrap()
      .then( () => refresh() )
  }

  useEffect(() =>{
    const group = data 
      ? groupBy(data.menu, (item) => item.categoryId)
      : groupBy(restaurant.menu, (item) => item.categoryId)
    setMenu(group)
  }, [data, restaurant])

  const renderCategory = useMemo(() => {
    if(!categories) return <></>;
    return Object.keys(menu).map((key) => {
      const category = categories.filter( (category: { id: string; }) => category.id === key)
      return <CategoryMenu key={key} category={category[0]} items={menu[key]} setItem={setItem} deleteCategory={handleDeleteDishes}/>;
    });
  }, [categories, menu, data]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="col-12 p-0" style={{ position: "relative" }}>
      <div className="flex align-items-center col-12" >
        <Button 
          icon="pi pi-angle-left" 
          className="p-button-rounded p-button-info p-button-outlined mr-2" 
          onClick={() => setPage('menu')}
          style={{ zIndex: 1000, backgroundColor: 'white' }}
        />
      </div>
      <div className="col-12 p-0">
        <h4 
          className="mt-1 mb-1 col-12 p-0 text-center"
          style={{
            position: "absolute",
            top: "13px"
          }}
        > Кухня </h4>

        <>
          <Button label="Добавить блюдо" onClick={handleAdd} className="p-button-raised p-button-success col-12 mb-1"/>
          
          <ScrollPanel style={{height: `calc(100vh - ${isMobile ? '211px' : '312px'})`}}>
            {renderCategory}
          </ScrollPanel>
          
          <AddDishesWin restaurantId={restaurant.id} refresh={refresh}/>
          <EditDishesWin item={item} refresh={refresh}/>
          <DeleteKitchenWin item={category} deleteItem={deleteCategoryKitchen} />
        </>

      </div>
    </div>
  );
}

export default KitchenEdit;
