import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import type { RootState } from '../../app/store'
import { IFiltersRestaurants, IRestaurant } from 'app/interfaces/restaurants'
import { restaurantsApi } from 'app/services/restaurants'

type RestaurantState = {
  list: IRestaurant[],
  filters: IFiltersRestaurants,
  pagination: {
    page: number,
    pageSize: number,
    count: number
  },
  categories: {
    kitchen: ICategory[],
    dishes: ICategory[],
  }
}

interface ILike {
  like: string
}

export interface ICategory {
  id: string;
  name: string;
  description: string;
}

const initialState = {
  list: [],
  pagination: {
    page: 1,
    pageSize: 10,
    count: 0
  }, 
  filters: {
    categories: [],
    cities: [],
    like: '',
    isFullness: false
  },
  categories: {
    kitchen: [],
    dishes: [],
  }
}

const slice = createSlice({
  name: 'restaurants',
  initialState: initialState as RestaurantState,
  reducers: {
    setLike: ( state, action: PayloadAction<ILike> ) => {
      const arg = action.payload;

      state.filters.like = arg.like
    },
    setFilter: ( state, action: PayloadAction<IFiltersRestaurants> ) => {
      const arg = action.payload;
  
      state.filters.categories = arg.categories
      state.filters.cities = arg.cities
      state.filters.like = arg.like
      state.filters.isFullness = arg.isFullness
    },
    setPage: ( state, action: PayloadAction<number> ) => {
      const page = action.payload;
  
      state.pagination.page = page
    },
    setSizePage: ( state, action: PayloadAction<number> ) => {
      const size = action.payload;
  
      state.pagination.pageSize = size
    },
    setCount: ( state, action: PayloadAction<number> ) => {
      const count = action.payload;
  
      state.pagination.count = count
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        restaurantsApi.endpoints.getRestaurants.matchFulfilled,
        (state, { payload }) => {
          state.list = payload.rows
          state.pagination.count = payload.count
        }
      )
  },
})

export const { 
  setLike, 
  setFilter, 
  setPage,
  setSizePage,
  setCount
} = slice.actions // eslint-disable-line

export default slice.reducer

// export const selectAuth = (state: RootState) => state.auth.isAuthenticated
export const selectAllRestaurants = (state: RootState) => state.restaurants.list
export const selectFiltersRestaurants = (state: RootState) => state.restaurants.filters
export const selectPaginationRestaurants = (state: RootState) => state.restaurants.pagination
export const selectCategories = (state: RootState) => state.restaurants.categories