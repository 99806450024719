import React, { useState } from "react";

import { ScrollPanel } from "primereact/scrollpanel";
import { Button } from "primereact/button";
import { show } from "features/windows/slice";
import { useDispatch } from "react-redux";
import { useGetTagsDishesQuery } from "app/services/requisites";
import { TableCategoriesDishes } from "features/restaurants/table.dishes.categories";
import AddKitchenWin from "./windows/add.kitchen.win";
import EditKitchenWin from "./windows/edit.kitchen.win";
import { ICategory } from "app/interfaces/categories";

export const MenuRestaurantsPage: React.FC = () => {
  const [ current, setCurrent ] = useState<ICategory | undefined>(undefined)
  const dispatch = useDispatch()
  const { } = useGetTagsDishesQuery(null)

  const handleAdd = () => dispatch(show({type: 'kitchen', name: 'add'}));

  return (
    <>
      <h1 style={{margin: '0px'}}>Категории блюд</h1>
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 140px)' }}>

        <div className="col-12 pl-0 flex flex-wrap align-items-baseline justify-content-between">
          <div className="flex flex-wrap">
            <Button label="Добавить" onClick={handleAdd} className="p-button-raised p-button-success mr-1"/>
          </div>

        </div>

        <div className="card">
          <TableCategoriesDishes setElement={setCurrent} />
        </div>

        <AddKitchenWin />
        <EditKitchenWin data={current} />
      </ScrollPanel>
    </>
  );
}

export default MenuRestaurantsPage;