import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { 
  selectAllRestaurants, 
  selectPaginationRestaurants, 
  selectFiltersRestaurants,
  selectCategories
} from 'features/restaurants/slice'

export const useRestaurants = () => {
  const restaurants = useSelector(selectAllRestaurants)
  const filter = useSelector(selectFiltersRestaurants)
  const pagination = useSelector(selectPaginationRestaurants)
  const categories = useSelector(selectCategories)

  return useMemo(() => ({ restaurants, filter, pagination, categories }), [restaurants, filter, pagination, categories])
}