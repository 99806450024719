import React from 'react';
import moment from "moment";
import { locale, addLocale } from "primereact/api";
import { dataPickerSetting } from "utils/settings";

import { ProgressBar } from 'primereact/progressbar';

import 'moment/locale/ru';

import './assets/styles/App.css';
import './assets/styles/index.css';
import './assets/styles/custom.css';
import './assets/styles/windows.css';
import './assets/styles/gallery.css';
import './assets/styles/dishes.css';

import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'primeflex/primeflex.min.css';

import 'react-pro-sidebar/dist/css/styles.css';
import 'react-dadata/dist/react-dadata.css';

import { Route, Routes } from 'react-router-dom';
import Login from 'features/auth/Login';
import { PrivateOutlet } from 'pages/PrivateOutlet';
import { useCurrentMeQuery } from 'app/services/auth';
import MainRestaurantsPage from 'pages/restaurants/main.restaurants.page';
import ListRestaurantsPage from 'pages/restaurants/list.restaurants.page';
import MenuRestaurantsPage from 'pages/restaurants/menu.restaurants.page';


moment.locale("ru");
addLocale("ru", dataPickerSetting);
locale("ru");

const App:React.FC = () => {

  const { isLoading } = useCurrentMeQuery()
  
  return <>
    { isLoading  
      ? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
      : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PrivateOutlet />}>
              
              <Route path="restaurants" element={<MainRestaurantsPage />} >
                <Route path="all" element={<ListRestaurantsPage />} />
                <Route path="menu" element={<MenuRestaurantsPage />} />
              </Route>

            </Route>
          </Routes>
      )
    }
    
  </>
};

export default App;
