import React, { useState } from 'react';
import { ExpansionIcon, ExpansionItem } from "components/expansion";
import DishesMenu from './dishes.menu';
import { ICategory } from 'app/interfaces/categories';
import { IDishes } from 'app/interfaces/restaurants';
import { Button } from 'primereact/button';

interface IProps {
  category: ICategory | null;
  items: IDishes[];
  setItem: (dish: IDishes) => void;
  deleteCategory: (category: ICategory) => void;
}

const CategoryMenu: React.FC<IProps> = (props: any) => {
  const [active, setActive] = useState(false);
  const {category, items, setItem, deleteCategory} = props;
  
  return (
    <div className="col-12 mt-3" key={category.id}>
      <div className="col-12 flex justify-content-between">
        <div className="col-10 p-0 flex align-items-center" onClick={()=> setActive(!active)} style={{cursor: 'pointer'}}>
          <h4 className="dishes-category-name mr-2 mt-2 mb-2">{category ? category.name : "Прочее ..."}({items.length}шт.)</h4>
          <ExpansionIcon
            active={active}
            setActive={()=>{}}
            className="align-self-end pt-2"
          />
        </div>

        <Button 
          icon="pi pi-trash" 
          aria-label="Cancel" 
          className="col-2 p-button-danger p-button-rounded"
          onClick={() => {
            deleteCategory(category)
          }}
        />
      </div>

      <ExpansionItem
        className="col-12 order-6 p-0"
        active={active}
        withLine
      >
        <DishesMenu items={items} setItem={setItem} />
      </ExpansionItem>
      
    </div>
  );
};

export default CategoryMenu;