import React from 'react';

import { ConfirmDialog } from 'primereact/confirmdialog';
import { useDispatch } from 'react-redux';
import { hide } from 'features/windows/slice';
import { useWindows } from 'hooks/useWindows';
import { ICategory } from 'app/interfaces/categories';
import { useDeleteDishesByKitchenMutation } from 'app/services/kitchen';

interface IProps {
  item: ICategory | null;
  deleteItem: (categoryId: string) => void;
}

const DeleteKitchenWin: React.FC<IProps> = (props: any) => {
  const { item, deleteItem } = props;
  const dispatch = useDispatch();
  const windows = useWindows();
  

  const accept = () => deleteItem( item.id );
  const reject = () => {}

  if(!item) return <></>;

  return <ConfirmDialog visible={windows.kitchen.delete} onHide={() => dispatch(hide({type: 'kitchen', name: 'delete'}))} 
      message={`Вы действительно хотите удалить категорию и все её содержимое?`}
      header="Предупреждение" icon="pi pi-exclamation-triangle" accept={accept} reject={reject} 
      className="win-confirm win-confirm--warning"
    />
}

export default DeleteKitchenWin;