import React from "react";
import { Dialog } from "primereact/dialog";
import { useDispatch } from "react-redux";
import { hide } from "features/windows/slice";
import { useWindows } from "hooks/useWindows";
import { ICategory } from "app/interfaces/categories";
import FormKitchenWin from "./form.kitchen.win";
import { useUpdateKitchenMutation } from "app/services/kitchen";

interface IProps {
  data: object | undefined; 
}

export const EditKitchenWin: React.FC<IProps> = (props: IProps) => {
  const { data } = props;
  const dispatch = useDispatch();
  const windows = useWindows();
  const [ updateKitchen ] = useUpdateKitchenMutation();

  const handleClose = () => {
    dispatch(hide({type: 'kitchen', name: 'edit'}))
  };
  const onSubmit = (values: ICategory) => {
    updateKitchen( values ).unwrap()
      .then( () => handleClose() )
  };

  if(!data) return <></>

  return (
    <Dialog header="Редактирование категории" visible={windows.kitchen.edit} className="col-12 p-0 md:col-6 lg:col-4" onHide={handleClose}>
      <FormKitchenWin initialData={data} onSubmit={onSubmit} onCancel={(e: any) => {
        e.preventDefault();
        handleClose()
      } }/>
    </Dialog>
  );
}

export default EditKitchenWin;