import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'

type WindowsState = {
  restaurants: any
  kitchen: any
  dishes: any
  categories: any
}

const initialState = {
  restaurants: {
    add: false,
    edit: false,
    deleteImage: false,
    filter: false,
  },
  kitchen: {
    add: false,
    edit: false,
    delete: false,
  },
  dishes: {
    add: false,
    edit: false,
  },
  categories: {
    dishes: false,
    kitchen: false,
  }
}

interface IShowWindow {
  type: string
  name: string
}

const slice = createSlice({
  name: 'windows',
  initialState: initialState as WindowsState,
  reducers: {
    show: ( state, action: PayloadAction<IShowWindow> ) => {
      const arg = action.payload;

      if(arg.type === 'restaurants') state[arg.type][arg.name] = true
      if(arg.type === 'kitchen') state[arg.type][arg.name] = true
      if(arg.type === 'dishes') state[arg.type][arg.name] = true
    },
    showCategory: ( state, action: PayloadAction<IShowWindow> ) => {
      const arg = action.payload;

      if(arg.type === 'dishes') state.categories[arg.type][arg.name] = true
      if(arg.type === 'kitchen') state.categories[arg.type][arg.name] = true
    },
    hide: ( state, action: PayloadAction<IShowWindow> ) => {
      const arg = action.payload;

      if(arg.type === 'restaurants') state[arg.type][arg.name] = false
      if(arg.type === 'kitchen') state[arg.type][arg.name] = false
      if(arg.type === 'dishes') state[arg.type][arg.name] = false
    },
    hideCategory: ( state, action: PayloadAction<IShowWindow> ) => {
      const arg = action.payload;

      if(arg.type === 'dishes') state.categories[arg.type][arg.name] = false
      if(arg.type === 'kitchen') state.categories[arg.type][arg.name] = false
    },
  }
})

export const { show, hide, showCategory, hideCategory } = slice.actions

export default slice.reducer

export const selectAllWindows = (state: RootState) => state.windows
export const selectRestaurantWindows = (state: RootState) => state.windows.restaurants