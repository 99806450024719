import ActionsColumn from "components/table/action.column";
import { show } from "features/windows/slice";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ICategory } from "./slice";
import { useGetCategoriesKitchenQuery } from "app/services/requisites";

interface IProps {
  setElement: (item: ICategory) => void;
}

export const TableCategoriesDishes: React.FC<IProps> = (props: IProps) => {
  const { setElement } = props;
  const dispatch = useDispatch();
  
  let { data, isLoading } = useGetCategoriesKitchenQuery(null);
  
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  })

  const handleEdit = (item: ICategory) => {
    setElement(item)
    dispatch(show({type: 'kitchen', name: 'edit'}))
  };

  let menu = (item: ICategory) => [
    {
      label: 'Редактировать', 
      icon: 'pi pi-fw pi-check-circle', 
      command:()=> handleEdit(item)
    }
  ]
        
  const action = (rowData: any) => <ActionsColumn menuItems={menu(rowData)} element={rowData} />
        
  return (
    <>
      <DataTable value={data} 
        scrollable={!isMobile} scrollHeight={!isMobile ? "calc(100vh - 310px)" : ""} 
        responsiveLayout="stack" breakpoint="768px"
        loading={isLoading}
      >
        <Column field="name" header="Название"></Column>
        <Column field="description" header="Описание"></Column>
        <Column body={action} style={{ maxWidth: '5rem' }}></Column>
      </DataTable>
    </>
  );
}